import { withRouter } from 'react-router-dom';

import { withFormik } from 'formik';
import * as yup from 'yup';

import { Auth } from 'aws-amplify';

import LoginForm from './LoginForm';

import { generatePortalUrl } from './../../../utils/constants';

/**
 * Formik HOC for form validation
 */
const LoginContainer = withFormik({
    mapPropsToValues: () => ({ email: '', password: '' }),
    validationSchema: yup.object().shape({
        email: yup.string()
            .email('Please enter a valid email address')
            .required('Please enter a valid email address'),
        password: yup.string()
            .required('Please enter a password'),
    }),
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        // props.setFirstTimeLoggedInState(true);
        props.setUser();
        const {email, password} = values;
        const emailString = email.toString().toLowerCase();
        const isValidEmail = 
                           emailString.substring(emailString.lastIndexOf('@')).toLowerCase() === (('@clinicallabs.com.au')) 
                        || emailString.substring(emailString.lastIndexOf('@')).toLowerCase() === (('@discretelogix.com')) 
                        || emailString.substring(emailString.lastIndexOf('@')).toLowerCase() === (('@binexsolutions.com'));
        if (!isValidEmail) {
            setSubmitting(false);
            return setErrors({ form: 'Please enter a valid email address' });
        }
        
        Auth.signIn(emailString, password)
            .then((success) => {
                // Form is no longer submitting
                setSubmitting(false);
                // console.log("API LOGIN")
                // console.log("Success properties:", Object.values(success));

                // If there's a challenge in the response, user needs to make a password for their first time login.
                if (success && success.challengeName && success.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    //console.log('New password required!');
                    props.setFirstTimeLoggedInState(true);
                    props.setUser(success);
                    
                // Otherwise, the user can go through
                } else {
                    //console.log('Logged in!');
                    // Update isAuthenticated to true
                    props.updateAuthState(true);
                    // Go to the Protocols page
                    props.history.push(generatePortalUrl('protocols'));
                }
            })
            .catch((error) => {
                console.log("Error:",error);
                // Form is no longer submitting
                setSubmitting(false);
                // Set an error message for the whole form.
                // Use it as error.form in the child component.
                
                if (error.message === 'Password attempts exceeded' ) {
                    setErrors({ form: "You have exceeded the maximum number of login attempts, please follow the 'Forgot your password?' link to reset password" });
                } else if (error.code === 'UserNotFoundException' || error.message === 'User does not exist.') {
                    setErrors({ form: 'User not registered for this portal, contact clinicaltrials@clinicallabs.com.au if you believe you should have access'});
                } else if (error.code === 'NotAuthorizedException' || error.message === 'Incorrect username or password.') {
                    setErrors({ form: 'Your email or password is incorrect' });
                } else {
                    setErrors({ form: error.message });
                }
            });
        
    },
    displayName: 'LoginForm', // helps with React DevTools
    validateOnBlur: false,
    validateOnChange: false,
})(LoginForm);

export default withRouter(LoginContainer);  