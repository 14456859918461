/**
 * Constants
 */

 var allTubes = null;
// export const API_BASE_URL = 'https://8c8yg2up6c.execute-api.ap-southeast-2.amazonaws.com/Test-web/';
// export const API_BASE_URL = 'https://8c8yg2up6c.execute-api.ap-southeast-2.amazonaws.com/uat-web/';
export const API_BASE_URL = 'https://api.ctrials.clinicallabs.com.au/prod-web/';

export const SEARCH_PARTICIPANT_BY_LAB_NUMBER_API = {
    POST: `${API_BASE_URL}searchparticipantdetailbylabnumber`,
}

export const PROTOCOL_API = {
    GET: `${API_BASE_URL}getprotocollist`,
}

export const ANALYTE_API = {
    GET: `${API_BASE_URL}getAllAnalytes`,
}

export const GET_TUBE_DETAILS_BY_KITCODE = {
    GET: `${API_BASE_URL}gettubedetailsbykitcode`,
}

export const ADD_PROTOCOL_API = {
    POST: `${API_BASE_URL}addnewprotocol`,
}

export const UPDATE_PROTOCOL_API = {
    POST: `${API_BASE_URL}updateprotocoloverview`,
}

export const INSERT_KIT_AND_TUBE_DETAILS = {
    POST: `${API_BASE_URL}insertkitdetails`,
}

export const GET_COHORTLIST_BY_PROTOCOLID_API = {
    GET: `${API_BASE_URL}getcohortlistbyprotocolid`,
}

export const UPSERT_COHORTLIST_API = {
    POST: `${API_BASE_URL}upsertcohortbycohortid`,
}

export const UPSERT_SCREENING_API = {
    POST: `${API_BASE_URL}upsertscreeningdetails`,
}

export const GET_SCREENING_BY_PROTOCOLID_API = {
    GET: `${API_BASE_URL}getscreeningbyprotocolid`,
}

export const UPDATE_SCREENING_STATUS_BY_PROTOCOLID_API = {
    POST: `${API_BASE_URL}updatescreeningstatusbyprotocolid`,
}

export const GET_SKIP_SCREENING_API = {
    GET: `${API_BASE_URL}skipscreening`,
}

export const GET_EPISODE_BY_COHORTID_API = {
    GET: `${API_BASE_URL}getepisodebycohortid`,
}

export const UPSERT_EPISODE_BY_COHORTID_API = {
    POST: `${API_BASE_URL}upsertepisodebycohortid`,
}

export const UPSERT_PACKING_DETAILS_BY_KITCODE = {
    POST: `${API_BASE_URL}upsertpackingdetailsbykitcode`,
}
export const GET_PACKING_DETAILS_BY_KITCODE = {
    GET: `${API_BASE_URL}getpackingdetailsbykitcode`,
}

export const GET_PANELS_BY_EPISODEID_API = {
    GET: `${API_BASE_URL}getpanelsbyepisodeid`,
}

export const UPSERT_PANELS_BY_EPISODEID_API = {
    POST: `${API_BASE_URL}upsertpanelsforepisode`,
}

export const GET_QUOTE_BY_PROTOCOLID_API = {
    GET: `${API_BASE_URL}getquotebyprotocolid`,
}

export const GET_EXPORT_BY_PROTOCOLID_API = {
    GET: `${API_BASE_URL}getexportdetailsbyprotocolid`,
}

export const GET_DELETE_PROTOCOL_BY_PROTOCOLID_API = {
    GET: `${API_BASE_URL}deleteprotocolbyprotocolid`,
}

export const PANEL_API = {
    GET: `${API_BASE_URL}getpaneldetails`,
}

export const TUBE_API = {
    GET: `${API_BASE_URL}gettubedetails`,
}

export const GET_MBS_CODES_API = {
    GET: `${API_BASE_URL}getmbscodedetails`,
}

export const UPSERT_MBS_CODES_API = {
    POST: `${API_BASE_URL}upsertmbscode`,
}

export const UPSERT_PANEL_BY_ID_API = {
    POST: `${API_BASE_URL}upsertpanelbyid`,
}

export const DELETE_PANEL_API = {
    GET: `${API_BASE_URL}deletepanelbypanelid`,
}

export const SCREENING_KITS_API = {
    GET: `${API_BASE_URL}getscreeningkits`,
}

export const EPISODE_KITS_API = {
    // GET: `${API_BASE_URL}getepisodekits`,
    GET: `${API_BASE_URL}getkits`,
}
export const LOCATIONS_LIST_API = `${API_BASE_URL}locationlist`

export const PANELS_LIST_API = `${API_BASE_URL}getprotocolpanels`

export const UPDATE_KIT_API = {
    POST: `${API_BASE_URL}updatekitdetails`,
}

export const PORTAL_URL = '/portal';
export function generatePortalUrl(url) {
    return `${PORTAL_URL}/${url}`;
}

export const LOGIN_URL = '/login';
export function generateLoginUrl(url) {
    return `${LOGIN_URL}/${url}`;
}

export const dateFormat = 'DD/MM/YYYY';
export const dateFormatAPI = 'MM/DD/YYYY';
export const dateDisplay = 'DD MMMM YYYY';

export function resetFilters(){
    localStorage.setItem('dateFilter', '1');
    localStorage.setItem('incomplete', '1');
    localStorage.setItem('complete', '0');
    localStorage.setItem('stringFilter', '');
    localStorage.setItem('kittype', 'episode');
}



export function setFetchTubes(_tubes){

    allTubes = _tubes;
    
}


export function getAllTubes(){
    

    // let tubes = {fetchedTubes:[
    //     {'tube_id':1,'Tube':'SST'},
    //     {'tube_id':2,'Tube':'EDTA'},
    //     {'tube_id':3,'Tube':'Sodium Citrate'},
    //     {'tube_id':4,'Tube':'Yellow Pot'},
    //     {'tube_id':5,'Tube':'Quantiferon Gold Kit'},
    //     {'tube_id':6,'Tube':'Pearl PTH'},
    //     {'tube_id':7,'Tube':'FlOx'},
    //     {'tube_id':8,'Tube':'4L Urine'}
    // ]}
    return allTubes;
}