import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import ProtocolSummary from './../ProtocolSummary';
import ScreeningForm from './ScreeningForm';
import Preloader from './../../../common/Preloader';

import { PANEL_API } from './../../../../utils/constants';
import { GET_TUBE_DETAILS_BY_KITCODE, INSERT_KIT_AND_TUBE_DETAILS, GET_SCREENING_BY_PROTOCOLID_API } from './../../../../utils/constants';
import { UPSERT_SCREENING_API } from './../../../../utils/constants';

import { generatePortalUrl } from './../../../../utils/constants';
import { getMasterTubeDetails } from '../../../common/MasterTubeDetails';
import ConfirmationDialog from '../../../common/ConfirmationDialog';

// Sample previous state
let previousState = [];


class ScreeningContainer extends Component {





    state = {
        forceUpdate: false,
        isFetchingPanels: false,
        isLoadingScreeningDetails: false,
        isSubmitting: false,
        isSubmittingBack: false,
        isSubmittingNext: false,
        isSubmittingExit: false,
        hasErrors: false,
        errors: {
            emptyPanel: false,
            duplicatePanels: false,
        },
        serverError: '',
        fetchedPanels: [],//all panels of system
        getScreeningbyProtocolId_API: [],
        panels: [
            {
                Panel_code: '',
                Panel_ID: '',
            }
        ],
        Kitcode: 'N/A',
        skipScreening: false,
        kitTubesDetail: [],
        fetchedTubes: [],
        showConfrimationDialog: false,
        CheckWOCBP: false,
        backupPanels: []
    }

    componentDidMount() {
        this.fetchPanels();
        this.getScreeningbyProtocolId_API();
        // this.getSkipScreeningbyProtocolId_API();

        getMasterTubeDetails((result) => {
            this.setState({ fetchedTubes: result });
        })

    }




    /**
     * Loads previous screening details to the screen (if screening is already created)
     */
    loadScreeningDetails = () => {

        if (previousState.length) {
            const panels = [];
            for (let item of previousState) {
                panels.push(
                    {
                        Panel_code: item.Panel_code,
                        Panel_ID: item.Panel_ID,
                    }
                )
            }

            //this.setState({ skipScreening: panels.length == 0 });// delete it

            // Just override the state
            this.setState({ panels });
            
        }

    }

    /**
     * Fetches the list of panels
     */
    fetchPanels = () => {

        this.setState({ isFetchingPanels: true });

        /* Uncomment to use API */
        const url = PANEL_API.GET;

        // Get the current session
        Auth.currentSession().then((response) => {
            // Grab the jwtToken to use as Authorization header
            const headers = { Authorization: response.idToken.jwtToken };

            // Fetch the Panels list
            axios.get(url, { headers })
                .then((response) => {
                    // console.log("fetchPanels = "+JSON.stringify(response))
                    if (response.data) {
                        const result = response.data.result;
                        this.setState({
                            fetchedPanels: result || [],
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isFetchingPanels: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isFetchingPanels: false });
        });
    }
    /**
        * Fetches Screening Details By Protocol ID using API
        */
    getScreeningbyProtocolId_API = () => {

        // resetting the previous state
        previousState = [];

        this.setState({ isLoadingScreeningDetails: true });


        const url = GET_SCREENING_BY_PROTOCOLID_API.GET;
        // Get the current session
        Auth.currentSession().then((response) => {
            // Grab the jwtToken to use as Authorization header
            const headers = { Authorization: response.idToken.jwtToken };
            let beforeCallTime = new Date().getTime();

            // Fetch the Screening Panels list
            axios.get(`${url}?protocolId=${this.props.protocolId}`, { headers })
                .then((response) => {

                    // console.log("getScreeningbyProtocolId_API = "+JSON.stringify(response))
                    let afterCallTime = new Date().getTime();
                    let loadTime = afterCallTime - beforeCallTime;

                    let array = url.split('/');
                    let endpoint = array[array.length - 1];


                    //Google analytics tracking
                    ReactGA.timing({
                        category: endpoint,
                        variable: 'responseTime',
                        value: loadTime, // in milliseconds
                        label: null
                    });

                    if (response.data) {
                        if (response.data.Screening_skipped == null) {

                            this.setState({ skipScreening: false })
                        } else {
                            this.setState({ skipScreening: response.data.Screening_skipped })
                        }
                        if (response.data[0] != null) {
                            const result = response.data[0].result;
                            
                            if (result.length > 0) {
                                this.setState({ Kitcode: result[0].Kitcode })
                                this.setState({ CheckWOCBP: result[0].Check_WOCBP })
                                this.getTubeDetailsByKitcode_API(result[0].Kitcode);
                            }
                            if (result.length) {
                                this.props.updateScreeningId(result[0].Screening_ID);
                                this.mapResultToPreviousState(result);
                            }
                        }

                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoadingScreeningDetails: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoadingScreeningDetails: false });
        });
    }

    getTubeDetailsByKitcode_API = (kitcode) => {
        this.setState({ isLoadingScreeningDetails: true });


        const url = GET_TUBE_DETAILS_BY_KITCODE.GET;
        // Get the current session
        Auth.currentSession().then((response) => {
            // Grab the jwtToken to use as Authorization header
            const headers = { Authorization: response.idToken.jwtToken };
            let beforeCallTime = new Date().getTime();

            // Fetch the Screening Panels list
            axios.get(`${url}?kitcode=${kitcode}`, { headers })
                .then((response) => {
                    // console.log("getTubeDetailsByKitcode_API = "+JSON.stringify(response))
                    let afterCallTime = new Date().getTime();
                    let loadTime = afterCallTime - beforeCallTime;

                    let array = url.split('/');
                    let endpoint = array[array.length - 1];
                    ReactGA.timing({
                        category: endpoint,
                        variable: 'responseTime',
                        value: loadTime, // in milliseconds
                        label: null
                    });

                    let tubes = response.data[0].result;
                    // console.log('dsadas',tubes)
                    for (let index = 0; index < tubes.length; index++) {
                        this.addItems(tubes[index].Tube, tubes[index].Quantity)

                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoadingScreeningDetails: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoadingScreeningDetails: false });
        });
    }

    /**
         * Update screening details to the Amazon API
         */
    upsertscreeningdetails_API = (nextStep) => {

        // console.log('upsertscreeningdetails_API called111')
        const isEditFromReviewScreen = this.props.isEditFromReviewScreen;

        // Next step to go to after successful form submit
        //console.log(nextStep);
        let button = '';

        if (nextStep === 1) {
            button = 'isSubmittingBack';
        } else if (nextStep === 3 || nextStep === 4) {
            button = 'isSubmittingNext';
        } else {
            button = 'isSubmittingExit';
        }

        // Set isSubmitting to true
        this.setState({
            isSubmitting: true,
            [button]: true,
        });

        const url = UPSERT_SCREENING_API.POST;

        let { deleted, added } = this.returnUpdates();
        // console.log(deleted);
        // console.log(added);
        //write code to update screening skip status
        const panels = [];

        if (!this.state.skipScreening) {
            for (let item of deleted) {
                panels.push(
                    {
                        panel_id: item.Panel_ID,
                        Status: 'R',
                    }
                )
            }

            for (let item of added) {
                panels.push(
                    {
                        panel_id: item.Panel_ID,
                        Status: 'N',
                    }
                )
            }
        }

        //console.log('panels');
        //console.log(panels);

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            let data = JSON.stringify({
                body: {
                    Screening_skipped: this.state.skipScreening,
                    protocolId: this.props.protocolId,
                    screeningId: this.props.screeningId,
                    modifiedby: this.props.username,
                    Panels: panels,
                    // Added WOCBP
                    CheckWOCBP: this.state.CheckWOCBP == false ? 0 : 1
                }
            })
            //console.log('upsert Method console enabled for Time Out error testing !!');
            // console.log(data);

            axios.post(url, data, { headers })
                .then((response) => {

                    // console.log('response111:', response)
                    // No longer submitting
                    this.setState({
                        isSubmitting: false,
                        [button]: false,
                    });

                    //console.log(response);

                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        console.error('Server error with status code 400 returned');
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }

                    if (response.data.body[0].result) {
                        // console.log('Success:', response.data.body[0].result)
                        if (!this.state.skipScreening) {
                            // console.log('updating screenign id:', response.data.body[0].result[0].ScreeningId)
                            this.props.updateScreeningId(response.data.body[0].result[0].ScreeningId);

                        } else {
                            // console.log('not updating screenign id:', response.data.body[0].result[0].ScreeningId)
                            if (this.state.skipScreening) {
                                if (nextStep === 1) {
                                    this.props.updateStepDone(2, 1);
                                    this.props.updateIsEditFromReviewScreen(false);
                                } else if (nextStep === 3) {
                                    this.props.updateStepDone(2, 3);
                                } else if (nextStep === 4 && isEditFromReviewScreen) {
                                    this.props.updateCurrentStep(4);
                                    this.props.updateIsEditFromReviewScreen(false);
                                } else {
                                    this.props.history.push(generatePortalUrl('protocols'));
                                }
                            }
                        }


                    }

                    setTimeout(() => {
                        this.upserttubedetails_API(nextStep)
                    }, 100);
                    // go to the next step
                    // if (nextStep === 1) {
                    //     this.props.updateStepDone(2, 1);
                    //     this.props.updateIsEditFromReviewScreen(false);
                    // } else if (nextStep === 3) {
                    //     this.props.updateStepDone(2, 3);
                    // } else if (nextStep === 4 && isEditFromReviewScreen) {
                    //     this.props.updateCurrentStep(4);
                    //     this.props.updateIsEditFromReviewScreen(false);
                    // } else {
                    //     this.props.history.push(generatePortalUrl('protocols'));
                    // }

                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        isSubmitting: false,
                        [button]: false,
                    });
                });
        });
    }


    upserttubedetails_API = (nextStep) => {
        if (this.props.screeningId == null)
            return;
        // console.log('ScreeningID: ', this.props.screeningId)
        const isEditFromReviewScreen = this.props.isEditFromReviewScreen;
        let button = '';
        if (nextStep === 1) {
            button = 'isSubmittingBack';
        } else if (nextStep === 3 || nextStep === 4) {
            button = 'isSubmittingNext';
        } else {
            button = 'isSubmittingExit';
        }
        // Set isSubmitting to true
        this.setState({
            isSubmitting: true,
            [button]: true,
        });
        const url = INSERT_KIT_AND_TUBE_DETAILS.POST;
        //console.log('panels');
        //console.log(panels);
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            let addedTubes = this.state.kitTubesDetail;
            let tubeDetails = [];
            for (let index = 0; index < addedTubes.length; index++) {
                tubeDetails.push([addedTubes[index].tube.tube_id, addedTubes[index].quantity])
            }
            let data = JSON.stringify({
                body: {
                    kit_code: this.state.Kitcode,
                    Day_period_id: null,
                    Screening_id: this.props.screeningId,
                    kit_date: new Date().toISOString(),
                    required: Number(this.props.protocolSummary.targetNoOfParticipantsToBeScreened),//get from props: TBD
                    tubedetails: tubeDetails

                }
            })



            //console.log('upsert Method console enabled for Time Out error testing !!');
            // console.log(data);

            axios.post(url, data, { headers })
                .then((response) => {

                    // console.log('response:', response)
                    // No longer submitting
                    this.setState({
                        isSubmitting: false,
                        [button]: false,
                    });

                    //console.log(response);

                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        console.error('Server error with status code 400 returned');
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }


                    //go to the next step
                    if (nextStep === 1) {
                        this.props.updateStepDone(2, 1);
                        this.props.updateIsEditFromReviewScreen(false);
                    } else if (nextStep === 3) {
                        this.props.updateStepDone(2, 3);
                    } else if (nextStep === 4 && isEditFromReviewScreen) {
                        this.props.updateCurrentStep(4);
                        this.props.updateIsEditFromReviewScreen(false);
                    } else {
                        this.props.history.push(generatePortalUrl('protocols'));
                    }

                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        isSubmitting: false,
                        [button]: false,
                    });
                });
        });
    }

    genrateNewKitcode_API = () => {
        if (this.props.screeningId == null) {
            return
        }
        // console.log('ScreeningID: ', this.props.screeningId)
        // Set isSubmitting to true
        this.setState({
            isSubmitting: true,
        });
        const url = INSERT_KIT_AND_TUBE_DETAILS.POST;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };
            let addedTubes = this.state.kitTubesDetail;
            let tubeDetails = [];
            for (let index = 0; index < addedTubes.length; index++) {
                tubeDetails.push([addedTubes[index].tube.tube_id, addedTubes[index].quantity])
            }
            let data = JSON.stringify({
                body: {
                    kit_code: this.state.Kitcode,
                    Day_period_id: null,
                    Screening_id: this.props.screeningId,
                    kit_date: new Date().toISOString(),
                    required: Number(this.props.protocolSummary.targetNoOfParticipantsToBeScreened),//get from props: TBD
                    regenrate: 1,
                    tubedetails: tubeDetails

                }
            })
            axios.post(url, data, { headers })
                .then((response) => {

                    this.setState({
                        isSubmitting: false
                    });
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        console.error('Server error with status code 400 returned');
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }
                    this.setState({
                        Kitcode: response.data.body.kitCode
                    }, () => {
                        // console.log('New Code Generated',this.state.Kitcode)
                    });

                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        isSubmitting: false,
                    });
                });
        });
    }


    updateRegenrationChoice = () => {
        this.genrateNewKitcode_API()
    }
    mapResultToPreviousState = (result) => {
        const newResult = result.map(item => {
            return {
                Panel_code: item.Panel_code,
                Panel_ID: item.panel_id,
            }
        });
        previousState = newResult;

        //console.log(previousState);



        this.loadScreeningDetails();
    }


    addPanel = () => {
        const panels = this.state.panels;
        const item = {
            Panel_code: '',
            Panel_ID: '',
        }
        this.setState({
            panels: [...panels, item]
        })
    }

    removePanel = (index) => {
        const panels = this.state.panels;





        // Don't let them delete if there's only one left
        if (panels.length <= 1) {
            return;
        }


        const fetchedPanels = this.state.fetchedPanels;
        let _tempPanel = fetchedPanels.find(fetchedPanel => panels[index].Panel_ID == fetchedPanel.Panel_ID)
        // console.log(_tempPanel)
        if (_tempPanel != null) {
            this.removeOrdecrementTubes(_tempPanel.tube, (-1 * _tempPanel.quantity))
        }

        panels.splice(index, 1);
        this.setState({ panels });
    }


    //////////////////////////////////////////////////////// Kit Tube Information functions START//////////////////
    addItems = (tube, quantity) => {

        // console.log('add items called')
        quantity = Number(quantity)
        this.addOrIncrementTubes(tube, quantity)
    }

    removeItem = (index) => {
        this.removingTubeIndex = index;
        this.showAlertToRemoveTube(this.state.kitTubesDetail[index].tube.Tube)

    }
    updateQuantity = (index, value) => {
        if (value < 0) {
            this.removeOrdecrementTubes(this.state.kitTubesDetail[index].tube.Tube, value)
        } else {
            this.addOrIncrementTubes(this.state.kitTubesDetail[index].tube.Tube, value, true)
        }
    }
    yesCallBackReduceQuantity = (_tube, _quantity) => {

        // console.log('decrementing',_quantity);
        let temp = [...this.state.kitTubesDetail];
        if (temp.length > 0) {
            let index = temp.findIndex(item => item.tube.Tube == _tube);
            if (index >= 0) {
                let t = { ...temp[index] };
                t.quantity += _quantity;
                temp[index] = t;
                if (temp[index].quantity <= 0) {
                    temp.splice(index, 1);
                }
            }
            this.setState({ kitTubesDetail: temp }, () => {

                // console.log('after decrementing',this.state.kitTubesDetail);
            });
        }
    }
    yesCallBackRemoveTube = () => {
        let index = this.removingTubeIndex;
        let temp = [...this.state.kitTubesDetail];
        temp.splice(index, 1)
        this.setState({ kitTubesDetail: temp })
    }
    noCallBack = () => {

    }
    showAlertToRemoveTube = (_tube) => {
        this.confirmationMessage = 'Do you want to remove ' + _tube + ' tube';
        this.yesCallBack = this.yesCallBackRemoveTube
        this.headingText = 'Are you sure?'
        this.noButtonText = 'No';
        this.yesButtonText = 'Yes';
        this.setState({ showConfrimationDialog: true })

    }
    showAlertToDecrementTube = (_tube) => {
        let quantity = 0;
        let temp = [...this.state.kitTubesDetail];
        if (temp.length > 0) {
            let index = temp.findIndex(item => item.tube.Tube == _tube);
            if (index >= 0) {
                let t = { ...temp[index] };
                quantity = t.quantity;

            }
        }


        this.yesCallBackReduceQuantity();


        // if(quantity > 1){
        // this.confirmationMessage = 'Do you want to reduce the quantity of ' + _tube + ' tubes?';
        // } else {
        // this.confirmationMessage = 'Do you want to remove ' + _tube + ' tube';
        //  }
        // this.yesCallBack = this.yesCallBackReduceQuantity
        // this.headingText = 'Are you sure?'
        // this.noButtonText = 'No';
        // this.yesButtonText = 'Yes';
        // this.setState({showConfrimationDialog:true})

    }


    removeOrdecrementTubes = (_tube, _quantity) => {
        let temp = [...this.state.kitTubesDetail];
        if (temp.length > 0) {
            let index = temp.findIndex(item => item.tube.Tube == _tube);
            if (index >= 0) {
                let t = { ...temp[index] };
                t.quantity += _quantity;
                temp[index] = t;
                if (temp[index].quantity <= 0) {
                    temp.splice(index, 1);
                }
            }
            this.setState({ kitTubesDetail: temp }, () => {
            });
        }

    }



    addOrIncrementTubes = (_tube, _quantity, shouldIncrement = false) => {

        // console.log('TUBE11',_tube)
        setTimeout(() => {
            let temp = [...this.state.kitTubesDetail];
            let index = temp.findIndex(item => item.tube.Tube == _tube);
            if (index >= 0) {
                if (shouldIncrement) {
                    let t = { ...temp[index] };
                    t.quantity += _quantity;
                    temp.splice(index, 1, t);
                }
            } else {

                // console.log('TUBE',_tube)
                temp.push({ tube: this.state.fetchedTubes.find(item => item.Tube == _tube), quantity: _quantity })

            }
            this.setState({ kitTubesDetail: temp }, () => {
                // console.log('tubes', this.state.fetchedTubes)
                // console.log('after incrementing',this.state.kitTubesDetail);
            });
        }, 100);

    }


    //////////////////////////////////////////////////////// Kit Tube Information functions END//////////////////



    updatePanel = (e, index) => {

        const value = e.target.value ? parseInt(e.target.value) : '';

        const panels = this.state.panels;
        const fetchedPanels = this.state.fetchedPanels;
        let _tempPanel = fetchedPanels.find(fetchedPanel => panels[index].Panel_ID == fetchedPanel.Panel_ID)
        if (_tempPanel != null) {
            this.removeOrdecrementTubes(_tempPanel.tube, (-1 * _tempPanel.quantity))
        }


        panels[index].Panel_ID = value;

        // Add further details to the panel
        const panelItem = fetchedPanels.find(fetchedPanel => value == fetchedPanel.Panel_ID);
        if (panelItem) {
            panels[index].Panel_code = panelItem.Panel_code || '';
            this.addOrIncrementTubes(panelItem.tube, panelItem.quantity);//add new tubes in tube detail for kit generation
            // If they did not select an panel, make everything else blank
        } else {
            panels[index].Panel_code = '';
        }

        // If the changed value is something, remove error message if any
        if (value && value !== '') {
            delete panels[index].error;
        }

        this.setState({ panels });
        console.log("ScreeingContainer: ", this.state.panels)
        this.setState({backupPanels: this.state.panels})
    }

    checkFSH_HSG = (panels, isActive) => {
        // console.log("*******************");
        // console.log(isActive);
        // console.log(this.state.backupPanels)
        // console.log(panels)
        if (isActive == true) {
            // console.log("is Active");
            // this.setState(prevState => ({
            //     panels: prevState.backupPanels
            // }));
            this.setState({ panels: this.state.backupPanels });
            // console.log("Active, panels "+JSON.stringify(panels));
            // return true;
        }
        else{
            this.setState({ backupPanels: panels });
        panels.find((o, i) => {
            if (o.Panel_code === 'FSH') {
                // console.log("is FSH");
                this.setState({ panels: isActive === false ? [o] : this.state.backupPanels });
                // console.log("FSH, panels "+JSON.stringify(panels));
                return true; // stop searching
            } else if (o.Panel_code === 'HCG') {
                // console.log("is HCG");
                this.setState({ panels: isActive === false ? [o] : this.state.backupPanels });
                // console.log("HCG, panels "+JSON.stringify(panels));
                return true; // stop searching
            }
            
        });
        }
        // if(isHCG_FSH){
        //     this.setState({ panels: this.state.backupPanels });
        //     return true;
        // }
        

    };

    enableWOCBP = () => {
        // console.log('enableWOCBP called');
        this.setState({ CheckWOCBP: !this.state.CheckWOCBP })
    }

    updateSkipScreeningStatus = (status) => {
        // console.log('status:', status)
        this.setState({ skipScreening: status })
    }

    validateForm = () => {
        const panels = this.state.panels;
        let hasErrors = false;

        let emptyPanel = false;
        let duplicatePanels = false;

        let uniqueArray = [];

        // console.log('status:', this.state.skipScreening)
        // Loop through and check if any of the list items are blank
        if (!this.state.skipScreening) {
            panels.map(panel => {
                if (!panel.Panel_ID || panel.Panel_ID === '') {
                    panel.error = true;
                    hasErrors = true;
                    emptyPanel = true;
                } else {
                    // panel.error = false;

                    // Check if any of the list items already exist
                    const findOne = uniqueArray.find(unique => unique == panel.Panel_ID);
                    if (findOne === undefined) {
                        uniqueArray.push(panel.Panel_ID);
                        delete panel.error;
                    } else {
                        panel.error = true;
                        hasErrors = true;
                        duplicatePanels = true;
                    }
                }
            });
        }
        return {
            panels,
            hasErrors,
            emptyPanel,
            duplicatePanels,
        };
    }

    handleSubmit = (e, nextStep) => {
        // Prevent form from submitting
        e.preventDefault();

        if (nextStep == 1) {
            this.props.updateStepDone(2, 1);
            return;
        }
        // Validate the form
        const validation = this.validateForm();
        const { panels, hasErrors, emptyPanel, duplicatePanels } = validation;

        this.setState({
            serverError: '', // reset server error
            panels,
            hasErrors,
            errors: {
                emptyPanel,
                duplicatePanels,
            },
        });

        // console.log("panels")
        // console.log(this.state.panels)

        if (hasErrors) {
            //console.log('Dont submit, has errors');
            // If there's no errors, submit the form
        } else {
            //console.log('Form is valid, submitting...');
            this.upsertscreeningdetails_API(nextStep);
        }
    }

    returnUpdates = () => {
        const panels = this.state.panels;
        // console.log('Previous state:');
        // console.log(previousState);
        // console.log('Current state:');
        // console.log(panels);

        // const noChange = _.intersectionWith(previousState, panels, _.isEqual);
        // console.log('No change (do not send this data):');
        // console.log(noChange);
        // console.log('prevPanels', previousState)
        // console.log('ThisPanels', panels)
        const deleted = _.differenceWith(previousState, panels, _.isEqual);
        // console.log('Deleted:', deleted);
        // console.log(deleted);

        const added = _.differenceWith(panels, previousState, _.isEqual);
        // console.log('Added:', added);
        // console.log(added);

        return { deleted, added };

    }

    render() {
        const { isFetchingPanels, isLoadingScreeningDetails, isSubmitting, isSubmittingBack, isSubmittingNext, isSubmittingExit } = this.state;
        return (
            <React.Fragment>
                <div className="screening__container">
                    <h3>Screening</h3>
                    <ProtocolSummary
                        protocolSummary={this.props.protocolSummary}
                    />
                    <ScreeningForm
                        ref={instance => { this.child = instance; }}
                        updateRegenrationChoice={this.updateRegenrationChoice}
                        kitTubesDetail={this.state.kitTubesDetail}
                        Kitcode={this.state.Kitcode}
                        addItems={this.addItems}
                        removeItem={this.removeItem}
                        updateQuantity={this.updateQuantity}
                        addPanel={this.addPanel}
                        removePanel={this.removePanel}
                        updatePanel={this.updatePanel}
                        handleSubmit={this.handleSubmit}
                        hasErrors={this.state.hasErrors}
                        errors={this.state.errors}
                        targetNoOfParticipantsToBeScreened={this.props.protocolSummary.targetNoOfParticipantsToBeScreened}
                        skipScreening={this.state.skipScreening}
                        fetchedPanels={this.state.fetchedPanels}
                        panels={this.state.panels}
                        getScreeningbyProtocolId_API={this.state.getScreeningbyProtocolId_API}
                        isSubmitting={this.state.isSubmitting}
                        isSubmittingBack={this.state.isSubmittingBack}
                        isSubmittingNext={this.state.isSubmittingNext}
                        isSubmittingExit={this.state.isSubmittingExit}
                        serverError={this.state.serverError}
                        updateSkipScreeningStatus={this.updateSkipScreeningStatus}
                        isEditFromReviewScreen={this.props.isEditFromReviewScreen}
                        // Task-1 
                        checkFSH_HSG={this.checkFSH_HSG}
                        enableWOCBP={this.enableWOCBP}
                        CheckWOCBP={this.state.CheckWOCBP}
                    />
                </div>
                {
                    (isFetchingPanels || isLoadingScreeningDetails || isSubmitting || isSubmittingBack || isSubmittingNext || isSubmittingExit) ?
                        <Preloader />
                        : null
                }


                <ConfirmationDialog
                    _tube={this._tube}
                    _quantity={this._quantity}
                    noButtonText={this.noButtonText}
                    yesButtonText={this.yesButtonText}
                    confirmationMessage={this.confirmationMessage}
                    modalIsOpen={this.state.showConfrimationDialog}
                    headingText={this.headingText}
                    yesCallBack={this.yesCallBack}
                    noCallBack={this.yesCallBack}
                    closeModal={() => { this.setState({ showConfrimationDialog: false }) }}
                />

            </React.Fragment>
        )
    }
}

export default withRouter(ScreeningContainer);

ScreeningContainer.propTypes = {
    protocolId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    screeningId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    username: PropTypes.string,
    updateScreeningId: PropTypes.func,
    updateStepDone: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
    protocolSummary: PropTypes.shape({
        protocolNo: PropTypes.string.isRequired,
        targetNoOfOnStudyParticipants: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        targetNoOfParticipantsToBeScreened: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
    }).isRequired,
    isEditFromReviewScreen: PropTypes.bool.isRequired,
    updateIsEditFromReviewScreen: PropTypes.func.isRequired,
    updateCurrentStep: PropTypes.func.isRequired,
};

