import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProtocolOverviewForm from './ProtocolOverviewForm';

import axios from 'axios';
import { Auth } from 'aws-amplify';
import _ from 'lodash';

import { LOCATIONS_LIST_API, PROTOCOL_API, PANELS_LIST_API } from './../../../../utils/constants';
import { ADD_PROTOCOL_API, UPDATE_PROTOCOL_API } from './../../../../utils/constants';
import { checkDuplicateProtocolNo } from './../../../../utils/utils';
import { isInt } from './../../../../utils/utils';
import { PANEL_API } from './../../../../utils/constants';

import './../../../../scss/protocol-overview.scss';

class ProtocolOverviewContainer extends Component {

    state = {
        existingProtocolNos: [],
        hasErrors: false,
        isEdit: false,
        isSubmitting: false,
        locations: [],
        selectedLocations: [],
        errors: {
            protocol_no: '',
            protocol_name: '',
            study_sponsor: '',
            copy_doctor: '',
            coordinator: '',
            participants: '',
            target_participants: '',
        },
        values: {
            protocol_no: '',
            protocol_name: '',
            study_sponsor: '',
            copy_doctor: '',
            ultra_code: '',
            coordinator: '',
            participants: '',
            target_participants: '',
            statusid: '1',
        },
        serverError: '',
        // Task-2
        fetchedPanels: [],//all panels of system
        isFetchingPanels: false,
        selectedGenderTest: []
    }

    componentDidMount() {
        // Task-2

        this.fetchProtocols();
        this.fetchLocationsList();

        this.fetchPanels();
        this.fetchPanelsSelected()

    }


    fetchLocationsList = () => {
        let url = LOCATIONS_LIST_API;
        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };
            axios.get(`${url}?protocolId=${this.props.protocolId}`, { headers })
                .then((response) => {
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        return;
                    }
                    // console.log(response)
                    this.originalLocations = response.data
                    // console.log(response.data.filter(e => e.protocol_id == this.props.protocolId))
                    this.setState({
                        locations: response.data,
                        selectedLocations: response.data.filter(e => e.protocol_id == this.props.protocolId && e.protocol_id != null)
                    })
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
        });
    }

    // Task-2
    fetchPanels = () => {
        this.setState({ isFetchingPanels: true });
        /* Uncomment to use API */
        const url = PANEL_API.GET;
        // Get the current session
        Auth.currentSession().then((response) => {
            // Grab the jwtToken to use as Authorization header
            const headers = { Authorization: response.idToken.jwtToken };
            // Fetch the Panels list
            axios.get(url, { headers })
                .then((response) => {
                    if (response.data) {

                        // console.log(response.data)
                        this.requiredPanels = response.data.result.filter(e => (e.Gender_specific == 'Female Only' || e.Gender_specific == 'Male Only') && e.Gender_specific != null)
                        this.setState({
                            fetchedPanels: response.data.result.filter(e => (e.Gender_specific == 'Female Only' || e.Gender_specific == 'Male Only') && e.Gender_specific != null) || [],
                        });

                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isFetchingPanels: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isFetchingPanels: false });
        });
    }

    fetchPanelsSelected = () => {
        this.setState({ isFetchingPanels: true });
        /* Uncomment to use API */
        const url = PANELS_LIST_API;
        // Get the current session
        Auth.currentSession().then((response) => {
            // Grab the jwtToken to use as Authorization header
            const headers = { Authorization: response.idToken.jwtToken };
            // Fetch the Panels list
            axios.get(`${url}?protocolId=${this.props.protocolId}`, { headers })
                .then((response) => {
                    if (response.data) {

                        // console.log(response.data)
                        this.requiredPanels = response.data
                        this.setState({
                            // selectedGenderTest: this.requiredPanels.filter(e => e.Panel_ID == this.props.Panel_ID && e.Panel_ID != null)
                            selectedGenderTest: this.requiredPanels
                        });

                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.setState({ isFetchingPanels: false });
                });

        }).catch((error) => {
            console.error(error);
            this.setState({ isFetchingPanels: false });
        });
    }


    addLocation = (location) => {


        location = location.value

        let locationObj = this.originalLocations.filter(e => e.name == location)[0]
        // console.log('ADD LOCATION', locationObj);
        let index = this.state.selectedLocations.findIndex(e => e.name == locationObj.name)
        if (index < 0) {
            let temp = [...this.state.selectedLocations]
            temp.push(locationObj)
            this.setState({ selectedLocations: temp })
        }
    }
    // Task-2
    addGenderTest = (panel) => {
        panel = panel.value
        let Panel_ID = this.requiredPanels.filter(e => e.Panel_code == panel)[0]
        // console.log('ADD Panel', Panel_ID)
        let index = this.state.selectedGenderTest.findIndex(e => e.Panel_code == Panel_ID.Panel_code)
        if (index < 0) {
            let temp = [...this.state.selectedGenderTest]
            temp.push(Panel_ID)
            this.setState({ selectedGenderTest: temp })
        }
    }
    removeLocation = (location) => {
        let temp = [...this.state.selectedLocations]
        temp.splice(temp.findIndex(e => e.name == location.name), 1)
        this.setState({ selectedLocations: temp })
    }
    // Task-2
    removeGenderTest = (panel) => {
        let temp = [...this.state.selectedGenderTest]
        temp.splice(temp.findIndex(e => e.Panel_code == panel.Panel_code), 1)
        this.setState({ selectedGenderTest: temp })
    }

    /**
     * Fetches the list of protocols from the Amazon API
     */
    fetchProtocols = () => {

        // Set isPageLoading to true before loading protocol details
        this.props.updateIsPageLoading(true);
        const url = PROTOCOL_API.GET;

        // Get the current session
        Auth.currentSession().then((response) => {
            // Grab the jwtToken to use as Authorization header
            const headers = { Authorization: response.idToken.jwtToken };
            //console.log(url);
            // Fetch the Protocol list
            axios.get(url, { headers })
                .then((response) => {
                    if (response.data && response.data.result != null) {
                        const result = response.data.result;

                        // Load the existing protocol numbers
                        const existingProtocolNos = result.map(item => item.Protocol_code);

                        // Check if this is an add or edit protocol
                        let isEdit = false;
                        let values = {
                            protocol_no: '',
                            protocol_name: '',
                            study_sponsor: '',
                            copy_doctor: '',
                            ultra_code: '',
                            coordinator: '',
                            participants: '',
                            target_participants: '',
                        }

                        if (this.props.protocolId !== null) {
                            const protocol = result.filter(item => item.Protocol_ID === this.props.protocolId)[0];

                            //setting the screening id
                            // console.log(protocol.Screening_ID);
                            this.props.updateScreeningId(protocol.Screening_ID);

                            values.protocol_no = protocol.Protocol_code || '';
                            values.protocol_name = protocol.Protocol_name || '';
                            values.study_sponsor = protocol.Study_sponser || '';
                            values.copy_doctor = protocol.Copy_doctor || '';
                            values.ultra_code = protocol.Ultra_code || '';
                            values.coordinator = protocol.Nucleus_coordinator || '';
                            values.participants = protocol.Study_participants || '';
                            values.target_participants = protocol.Screening_participants || 0;
                            values.statusid = protocol.Status_id || '';
                            isEdit = true;

                        }

                        this.setState({
                            existingProtocolNos,
                            isEdit,
                            values,
                        });

                        // Page has finished loading. Set isPageLoading to false.
                        this.props.updateIsPageLoading(false);
                    } else {
                        this.props.updateIsPageLoading(false);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });

        }).catch((error) => {
            console.error(error);
        });

    }

    /**
    * Add protocols to the Amazon API
    */
    addProtocol = () => {

        // Form values for API submission
        const values = this.state.values;
        const addurl = ADD_PROTOCOL_API.POST;
        // console.log('Locations', this.state.selectedLocations)
        const data = JSON.stringify({
            body: {
                number: values.protocol_no,
                name: values.protocol_name,
                sponsor: values.study_sponsor,
                copydoctor: values.copy_doctor,
                ultracode: values.ultra_code,
                coord: values.coordinator,
                onstudyparti: values.participants,
                screenparti: values.target_participants,
                statusid: '1', // always '1' for a new Protocol
                user: this.props.username,
                locations: this.mapProtocolLocations(this.props.protocolId, this.originalLocations, this.state.selectedLocations),
                // Task-2
                panels: this.mapProtocolPanels(this.props.protocolId, this.requiredPanels, this.state.selectedGenderTest),

            }
        });

        this.addOrUpdateProtocol(addurl, data);
    }

    mapProtocolLocations = (protocol_id, originalLocations, locations) => {
        let temp = []
        locations.forEach(e => {
            let location_id = this.originalLocations[originalLocations.findIndex(l => l.name == e.name)].id
            temp.push([protocol_id, location_id])

        })
        // console.log('Location Array', temp)
        return temp
    }

    mapProtocolPanels = (protocol_id, requiredPanels, panels) => {
        let temp = []
        panels.forEach(e => {
            let panel_id = this.requiredPanels[requiredPanels.findIndex(l => l.Panel_code == e.Panel_code)].Panel_ID
            temp.push([protocol_id, panel_id])

        })

        // console.log('Panels Array', temp)
        return temp
    }
    /**
    * Update an existing protocol
    */
    updateProtocol = () => {

        // Form values for API submission
        const values = this.state.values;
        const updateurl = UPDATE_PROTOCOL_API.POST;
        // console.log('Locations', this.state.selectedLocations)

        const data = JSON.stringify({
            body: {
                protocolId: this.props.protocolId.toString(),
                number: values.protocol_no,
                name: values.protocol_name,
                sponsor: values.study_sponsor,
                copydoctor: values.copy_doctor,
                ultracode: values.ultra_code,
                coord: values.coordinator,
                onstudyparti: values.participants.toString(),
                screenparti: values.target_participants.toString(),
                statusid: values.statusid,
                user: this.props.username,
                locations: this.mapProtocolLocations(this.props.protocolId, this.originalLocations, this.state.selectedLocations),
                // Task-2
                panels: this.mapProtocolPanels(this.props.protocolId, this.requiredPanels, this.state.selectedGenderTest),

            }
        });
        // console.log(data)
        this.addOrUpdateProtocol(updateurl, data);
    }

    addOrUpdateProtocol = (url, data) => {
        const values = this.state.values;
        const isEditFromReviewScreen = this.props.isEditFromReviewScreen;

        //console.log(url);

        this.setState({ isSubmitting: true });

        Auth.currentSession().then((response) => {
            const headers = { Authorization: response.idToken.jwtToken };

            //console.log(data);

            axios.post(url, data, { headers })
                .then((response) => {

                    // console.log(response);

                    // If successful:
                    this.setState({
                        // No longer submitting
                        isSubmitting: false,
                    });

                    // Status code 400 is a server error.
                    if (response.data.statusCode && response.data.statusCode === 400) {
                        console.error('Server error with status code 400 returned');
                        this.setState({
                            serverError: 'There was an error submitting the form. Please try again.',
                        });
                        return;
                    }

                    // If it's a new protocol, return and set the state of the new protocol ID
                    if (!this.state.isEdit && this.props.protocolId === null) {
                        const protocolId = response.data.result[0].ProtocolId || '';
                        this.props.updateProtocolId(protocolId);
                    }

                    // Pass some form values to the protocol summary
                    this.props.updateProtocolSummary(values.protocol_no, values.participants, values.target_participants);

                    // Check if user came here from Review and Export screen, and redirect back if so.
                    if (isEditFromReviewScreen) {
                        this.props.updateCurrentStep(4);
                        this.props.updateIsEditFromReviewScreen(false);
                    } else {
                        // Update step 1 is done, and move to step 2 (Screening)
                        this.props.updateStepDone(1, 2);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        serverError: 'There was an error submitting the form. Please try again.',
                        // No longer submitting
                        isSubmitting: false,
                    });
                });
        });

    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        const values = this.state.values;
        this.setState({
            values: {
                ...values,
                [name]: value
            }
        });
    }

    validateForm = () => {
        const values = this.state.values;
        const isEdit = this.state.isEdit;
        const currentErrors = {};
        let hasErrors = false;

        // Protocol number
        if (!values.protocol_no) {
            currentErrors.protocol_no = 'Please enter a valid protocol number';
            hasErrors = true;
        } else if (!isEdit && this.checkDuplicateProtocolNo(values.protocol_no)) {
            currentErrors.protocol_no = 'This protocol number already exists';
            hasErrors = true;
        } else {
            currentErrors.protocol_no = '';
        }

        // protocol_name
        if (!values.protocol_name) {
            currentErrors.protocol_name = 'Please enter a valid protocol name';
            hasErrors = true;
        } else {
            currentErrors.protocol_name = '';
        }

        // study_sponsor
        if (!values.study_sponsor) {
            currentErrors.study_sponsor = 'Please enter a valid study sponsor\'s name';
            hasErrors = true;
        } else {
            currentErrors.study_sponsor = '';
        }

        // coordinator
        if (!values.coordinator) {
            currentErrors.coordinator = 'Please enter a valid Clinical Research Coordinator\'s name';
            hasErrors = true;
        } else {
            currentErrors.coordinator = '';
        }

        // participants
        if (!values.participants || values.participants <= 0 || values.participants > 999 || !isInt(values.participants)) {
            currentErrors.participants = 'Please enter a number between 1 and 999';
            hasErrors = true;
        } else {
            currentErrors.participants = '';
        }

        // target_participants
        if (values.target_participants < 0 || values.target_participants > 2200 || !isInt(values.target_participants)) {
            currentErrors.target_participants = 'Please enter a number between 0 and 2200, In case of 0 you will have to skip screening option.';

            hasErrors = true;
        }
        else if (parseInt(values.target_participants) < parseInt(values.participants) && parseInt(values.target_participants) != 0) {
            currentErrors.target_participants = 'Please ensure that the number of participants to be screened is not less than the target number of participants to be dosed';
            hasErrors = true;
        } else {
            currentErrors.target_participants = '';
        }

        this.setState({
            errors: currentErrors,
            hasErrors,
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.validateForm();

        if (this.state.hasErrors) {
            //console.log('Dont submit, has errors');
        } else {
            const isEdit = this.state.isEdit;

            if (isEdit) {
                this.updateProtocol();
            } else {
                this.addProtocol();
            }
        }
    }

    handleBlurForProtocolNo = (e) => {
        // Trim the protocol number
        const protocol_no = _.trim(e.target.value);
        const values = this.state.values;
        const errors = this.state.errors;
        let errorMessage = '';

        // Set the state to the trimmed value, then perform validation check
        this.setState({
            values: {
                ...values,
                protocol_no,
            }
        }, () => {
            if (!protocol_no) {
                errorMessage = 'Please enter a valid protocol number';
            } else if (this.checkDuplicateProtocolNo(protocol_no)) {
                errorMessage = 'This protocol number already exists';
            } else {
                errorMessage = '';
            }

            this.setState({
                errors: {
                    ...errors,
                    protocol_no: errorMessage,
                }
            });
        })

    }

    checkDuplicateProtocolNo = (value) => {
        const existingProtocolNos = this.state.existingProtocolNos;
        const check = checkDuplicateProtocolNo(existingProtocolNos, value);
        return check;
    }

    render() {

        const { serverError } = this.state;

        return (
            <div className="protocol-overview__container row">
                <div className="protocol-overview col-8">
                    <div className="protocol-overview__title">
                        <h3>Protocol overview</h3>
                    </div>

                    {
                        serverError ?
                            <div className="form-errors__container row">
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        <small>
                                            {serverError}
                                        </small>
                                    </div>
                                </div>
                            </div> : null
                    }

                    <ProtocolOverviewForm
                        locations={this.state.locations}
                        selectedLocations={this.state.selectedLocations}
                        addLocation={this.addLocation}
                        removeLocation={this.removeLocation}
                        handleChange={this.handleChange}
                        validateForm={this.validateForm}
                        handleSubmit={this.handleSubmit}
                        handleBlurForProtocolNo={this.handleBlurForProtocolNo}
                        checkDuplicateProtocolNo={this.checkDuplicateProtocolNo}
                        errors={this.state.errors}
                        values={this.state.values}
                        isEdit={this.state.isEdit}
                        isSubmitting={this.state.isSubmitting}
                        isEditFromReviewScreen={this.props.isEditFromReviewScreen}
                        // Task-2
                        fetchedPanels={this.state.fetchedPanels}
                        selectedGenderTest={this.state.selectedGenderTest}
                        addGenderTest={this.addGenderTest}
                        removeGenderTest={this.removeGenderTest}
                    />
                </div>
            </div>


        )
    }
}

export default ProtocolOverviewContainer;

ProtocolOverviewContainer.propTypes = {
    protocolId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    username: PropTypes.string,
    updateProtocolId: PropTypes.func.isRequired,
    updateStepDone: PropTypes.func.isRequired,
    updateProtocolSummary: PropTypes.func.isRequired,
    existingProtocolNos: PropTypes.arrayOf(PropTypes.string),
    updateIsPageLoading: PropTypes.func.isRequired,
    updateScreeningId: PropTypes.func.isRequired,
    isEditFromReviewScreen: PropTypes.bool.isRequired,
    updateCurrentStep: PropTypes.func.isRequired,
    updateIsEditFromReviewScreen: PropTypes.func.isRequired,
};
